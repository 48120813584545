import * as React from "react";

import { Container, WideContent } from "./Layouts";
import { Smallcaps } from "./Prose";
import { Wagtail } from "./Wagtail";
import { Image } from "./Image";

export const ListingHeader = ({ images, page, snippets }) => (
  <Container>
    <div className="mb-12 flex justify-between items-center">
      <div className="flex flex-col justify-center">
        {!!page?.subheader && <Smallcaps>{page?.subheader}</Smallcaps>}
        {!!page?.header && (
          <h2 className="mt-2 text-4xl leading-9 tracking-tight sm:text-5xl sm:leading-10">
            {page?.header}
          </h2>
        )}
      </div>
      {!!page?.headerImage && (
        <Image
          className="w-24 sm:w-36"
          src={page?.headerImage?.src}
          alt={page?.headerImage?.title}
        />
      )}
    </div>
    {page?.headerText && (
      <WideContent>
        <Wagtail
          sections={page?.headerText}
          snippets={snippets}
          images={images}
        />
      </WideContent>
    )}
  </Container>
);
