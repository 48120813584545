import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";
import { useQueryParam, ArrayParam } from "use-query-params";

import { unwrap } from "../components/WagtailWrapper";
import {
  GrayInset,
  Grid,
  Content,
  Section,
  WideContent,
  TightContent,
  Frame,
} from "../components/Layouts";
import { ListingHeader } from "../components/ListingHeader";
import { EmptyList } from "../components/EmptyList";
import { HistologyCard, HistologyCardFragment } from "../components/Card";
import { Filters } from "../components/Facets";

import EmptyImage from "../images/undraw_medical_research_qg4d.svg";
import { SectionFragment } from "../components/Wagtail";

import { DataTypesIcon, BrainTumorTypeIcon } from "../components/Images";
import { IconStats } from "../components/Stats";

const getFacets = (items) =>
  [
    {
      label: "Brain Tumor Types",
      options: Object.values(
        items?.reduce((acc, { title }) => {
          const text = title;
          if (!acc[text]) {
            acc[text] = { label: text, value: title };
          }
          return acc;
        }, {})
      ).sort((a, b) => (a.label > b.label ? 1 : -1)),
    },
    {
      label: "Available Biospecimens",
      options: Object.values(
        items?.reduce((acc, { bioMb, bioFft, bioCsf, bioMps }) => {
          if (bioMb > 0) {
            const text = "Match Blood";
            acc[text] = { label: text, value: text };
          }
          if (bioFft > 0) {
            const text = "Flash-Frozen Tissue";
            acc[text] = { label: text, value: text };
          }
          if (bioCsf > 0) {
            const text = "Cerebrospinal Fluid";
            acc[text] = { label: text, value: text };
          }
          if (bioMps > 0) {
            const text = "Matched Parental Specimens";
            acc[text] = { label: text, value: text };
          }

          return acc;
        }, {})
      ).sort((a, b) => (a.label > b.label ? 1 : -1)),
    },
    {
      label: "Available Pre-Clinical Models",
      options: Object.values(
        items?.reduce((acc, { preClinicalPdx, preClinicalCellLines }) => {
          if (preClinicalPdx > 0) {
            const text = "Preclinical patient derived xenografts";
            acc[text] = { label: text, value: text };
          }
          if (preClinicalCellLines > 0) {
            const text = "Preclinical cell lines";
            acc[text] = { label: text, value: text };
          }
          return acc;
        }, {})
      ).sort((a, b) => (a.label > b.label ? 1 : -1)),
    },
    {
      label: "Available Data Types",
      options: Object.values(
        items?.reduce((acc, { dataTypes = [] }) => {
          dataTypes.forEach((dt) => {
            const text = dt?.name;
            if (text) {
              acc[text] = { label: text, value: text };
            }
          });
          return acc;
        }, {})
      ).sort((a, b) => (a.label > b.label ? 1 : -1)),
    },
  ].sort((a, b) => a.options.length - b.options.length);

const normalize = (items) => {
  return items
    ?.filter((p) => p?.lastPublishedAt)
    ?.map((item) => {
      item.tags = [
        item?.title,
        item?.label,
        item?.bioMb > 0 && "Match Blood",
        item?.bioFft > 0 && "Flash-Frozen Tissue",
        item?.bioCsf > 0 && "Cerebrospinal Fluid",
        item?.bioMps > 0 && "Matched Parental Specimens",
        item?.preClinicalPdx > 0 && "Preclinical patient derived xenografts",
        item?.preClinicalCellLines > 0 && "Preclinical cell lines",
        ...item?.dataTypes.map((dt) => dt.name),
      ].filter(Boolean);

      return item;
    })
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return b.enrolledSubjects - a.enrolledSubjects;
    });
};

export const Component = ({ page, snippets, images }) => {
  const [data, setData] = useState(page?.children);
  const [facets, setFacets] = useState([]);
  const [items, setItems] = useState(data);
  const [filters, setFilters] = useQueryParam("filters", ArrayParam);

  useEffect(() => {
    setData(normalize(data));
  }, [page]);

  useEffect(() => {
    setItems(
      data?.filter((e) =>
        filters ? filters.some((f) => e.tags?.includes(f)) : true
      )
    );
  }, [filters, data]);

  useEffect(() => {
    setFacets(getFacets(data));
  }, [items]);

  return (
    <Frame page={page} url={`histologies`} title={page?.title}>
      <Section>
        <Content>
          <ListingHeader images={images} page={page} snippets={snippets} />
        </Content>
        <TightContent>
          <Filters facets={facets} filters={filters} setter={setFilters} />
          <div className="mt-12 flex-wrap flex center justify-around">
            <IconStats
              image={<BrainTumorTypeIcon />}
              count={items?.length}
              title="Tumor Types"
            />
            <IconStats
              image={<DataTypesIcon />}
              count={
                facets?.find((x) => x?.label === "Available Data Types")
                  ?.options?.length
              }
              title="Data Types"
            />
          </div>
        </TightContent>
      </Section>
      <EmptyList
        items={items}
        message={"No Histologies Found."}
        image={EmptyImage}
      />
      {!!(items?.length > 0) && (
        <GrayInset>
          <WideContent>
            <Grid>
              {items?.map((m) => (
                <HistologyCard key={m.slug} item={m} />
              ))}
            </Grid>
          </WideContent>
        </GrayInset>
      )}
    </Frame>
  );
};

export const previewQuery = gql`
  query previewHistologyListingPage($contentType: String!, $token: String!) {
    images {
      id
      title
      src
    }
    snippets {
      __typename
      ... on CallToAction {
        name
        url
        document {
          id
          file
        }
        id
      }
    }
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on HistologyListingPage {
        header
        subheader
        headerText {
          ...SectionFragment
        }
        headerImage {
          title
          src
        }
        children {
          lastPublishedAt
          ...HistologyCardFragment
          ... on HistologyPage {
            bioMb
            bioCsf
            bioMps
            bioFft
            preClinicalPdx
            preClinicalCellLines
            enrolledSubjects
            dataTypes {
              name
            }
          }
        }
      }
    }
  }
  ${HistologyCardFragment}
  ${SectionFragment}
`;

export const query = graphql`
  query HistologyListingPage {
    wagtail {
      images {
        id
        title
        src
      }
      snippets {
        __typename
        ... on Wagtail_CallToAction {
          name
          url
          document {
            id
            file
          }
          id
        }
      }
      page(slug: "histologies") {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_HistologyListingPage {
          header
          subheader
          headerText {
            ...Wagtail_SectionFragment
          }
          headerImage {
            title
            src
          }
          children {
            lastPublishedAt
            ...Wagtail_HistologyCardFragment
            ... on Wagtail_HistologyPage {
              bioMb
              bioCsf
              bioMps
              bioFft
              preClinicalPdx
              preClinicalCellLines
              enrolledSubjects
              dataTypes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
