import * as React from "react";

import { Image } from "./Image";

export const EmptyList = ({ items = [], message = "No results.", image }) =>
  items?.length > 0 ? null : (
    <div className="mt-24 max-w-xl mx-auto flex flex-col justify-center items-center px-8 py-10 sm:px-12 sm:py-8 lg:px-16">
      <h2 className="text-4xl leading-9 tracking-tight sm:text-5xl sm:leading-10 whitespace-nowrap">
        {message}
      </h2>
      <Image className="mt-12  w-full" src={image} alt="Empty List" />
    </div>
  );
